import { Card } from '../../../../../../data';

export interface FooterProps {
  card: Card;
}

export enum FilterTabsValue {
  All = 'all',
  WaitingFor = 'waiting-for',
  Done = 'done',
  ToMe = 'to-me',
}
