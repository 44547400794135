import { Group, Stack, Text } from '@mantine/core';
import { IconPointFilled } from '@tabler/icons-react';
import { FC } from 'react';

import { TimeAgo } from '../../../../../../../../../../components/TimeAgo';
import { TimelineItemProps } from './types';

export const TimelineItem: FC<TimelineItemProps> = ({ isMe, user, time, rightSection, children }) => (
  <Stack gap="8px">
    <Group gap="8px" mt="-4px">
      <Text c="dark.7" fw="500">
        {user?.fullname} {isMe ? '(me)' : ''}
      </Text>

      <Group gap="8px" align="center">
        {time && (
          <>
            <IconPointFilled size={12} style={{ color: '#8a92a9' }} />
            <TimeAgo time={time} />
          </>
        )}

        {rightSection && (
          <>
            <IconPointFilled size={12} style={{ color: '#8a92a9' }} />
            {rightSection}
          </>
        )}
      </Group>
    </Group>

    {children}
  </Stack>
);
