import { Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC, useEffect } from 'react';

import { CustomButton } from '../../../../../../../../../../components/CustomButton';
import { DeadlineBadge } from '../../../../../../../../../../components/DeadlineBadge';
import { useAuthContext } from '../../../../../../../../../../contexts';
import { isIntermediateResponse } from '../../../../../../../../../../utils';
import { isRequestComplete } from '../../utils';
import { TimelineItem } from '../TimelineItem';
import { RequestForm, RequestResponse, ResponseStatusBadge } from './components';
import { ResponderCard } from './components/ResponderCard';
import { ResponderItemProps } from './types';

export const ResponderItem: FC<ResponderItemProps> = ({ card, user, request }) => {
  const [isEditResponseOpen, { toggle: toggleEditResponse, close: closeEditResponse }] = useDisclosure(false);
  const { currentUserId } = useAuthContext();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { withdrawn, tmodified, tdeadline, responder_id } = request;

  const isComplete = (isRequestComplete(request) && !isEditResponseOpen) || request.withdrawn;

  const isMe = user?.id === currentUserId;
  const time = isRequestComplete(request) || request.withdrawn ? tmodified : undefined;

  const shouldShowCommentLabel = !withdrawn && !isComplete && isMe;
  const shouldShowDeadline =
    !withdrawn && tdeadline && (!isComplete || (isComplete && tdeadline < new Date().getTime()));
  const isAllowedToEditResponse = isIntermediateResponse(request) && responder_id === currentUserId;

  useEffect(() => {
    closeEditResponse();
  }, [closeEditResponse, request]);

  return (
    <TimelineItem
      isMe={isMe}
      user={user}
      time={time}
      rightSection={
        <>
          {shouldShowDeadline && <DeadlineBadge isComplete={isComplete} tdeadline={request.tdeadline} />}
          {isAllowedToEditResponse && (
            <CustomButton onClick={toggleEditResponse} variant="outline" size="compact-sm">
              Edit Response
            </CustomButton>
          )}
        </>
      }
    >
      <ResponderCard request={request}>
        <Group justify="space-between">
          {shouldShowCommentLabel && (
            <Text c="dark.7" fw={500}>
              Comment:
            </Text>
          )}

          <Group gap="12px" align="center">
            <ResponseStatusBadge request={request} />
          </Group>
        </Group>
        {!withdrawn && isComplete && <RequestResponse request={request} />}
        {!withdrawn && isMe && <RequestForm card={card} request={request} isComplete={isComplete} />}
      </ResponderCard>
    </TimelineItem>
  );
};
