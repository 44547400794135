import { Group, Paper, Stack, Text } from '@mantine/core';
import { FC, useState } from 'react';

import { useCardsManager, useSetDocumentTitle } from '../../../../hooks';
import { EditableTitle, Footer, FullCardEditZone, FullCardHeader } from './components';

interface FullCardProps {
  cardId: string;
}

export const FullCard: FC<FullCardProps> = ({ cardId }) => {
  const { getCard, updateCard } = useCardsManager();

  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const card = getCard(cardId);

  const handleSaveTitle = async (title: string) => {
    if (!card) {
      return;
    }

    const { id, document, tags, state } = card;
    await updateCard({ id, document, tags, state, description: title });

    setIsEditingTitle(false);
  };

  useSetDocumentTitle(card?.description || 'Card');

  if (!card || card.id === '') {
    return <Text>Loading...</Text>;
  }

  return (
    <Paper shadow="xs" p="md">
      <Stack>
        <FullCardHeader card={card} />
        <Group gap="8px">
          <EditableTitle
            title={card.description}
            isEditing={isEditingTitle}
            onSaveTitle={handleSaveTitle}
            onCancel={() => setIsEditingTitle(false)}
            onClick={() => setIsEditingTitle(true)}
          />
        </Group>
        <FullCardEditZone card={card} />
        <Footer card={card} />
      </Stack>
    </Paper>
  );
};
