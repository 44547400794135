import { Divider, Group, Stack } from '@mantine/core';
import { FC, useState } from 'react';

import { CustomButton } from '../../../../../../components/CustomButton';
import { RequestActions } from '../../../../../../components/RequestActions';
import { SendRequestForm } from '../../../../../../components/RequestActions/components';
import { FilterTabs, Request } from './components';
import classNames from './Footer.module.css';
import { useFilteredRequests } from './hooks';
import { FooterProps } from './types';

export const Footer: FC<FooterProps> = ({ card }) => {
  const { activeTab, handleTabChange, filteredRequestsCount, currentRequests } = useFilteredRequests({ card });
  const [showCreateRequest, setShowCreateRequest] = useState(false);

  return (
    <Stack>
      <RequestActions card={card} hideCreateForm />
      <Divider />
      <Group justify="space-between">
        <FilterTabs
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          filteredRequestsCount={filteredRequestsCount}
        />
        <CustomButton
          className={classNames.addRequestBtn}
          onClick={() => {
            setShowCreateRequest(() => !showCreateRequest);
          }}
        >
          Add Request
        </CustomButton>
      </Group>

      {showCreateRequest && (
        <SendRequestForm
          card={card}
          onHide={() => {
            setShowCreateRequest(false);
          }}
        />
      )}

      <Stack gap="24px">
        {currentRequests.map((request) => (
          <Request card={card} key={request.id} request={request} />
        ))}
      </Stack>
    </Stack>
  );
};
