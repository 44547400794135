import { Loader, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FC, useEffect, useMemo, useState } from 'react';

import { Editor } from '../../../../../../components/Editor';
import { VersioningModal } from '../../../../../../components/VersioningModal';
import { useAdvancedEditor, useCardsManager, useLeaveConfirmation } from '../../../../../../hooks';
import { FullCardEditZoneProps } from './types';

export const FullCardEditZone: FC<FullCardEditZoneProps> = ({ card }) => {
  const { getCard, updateCard } = useCardsManager();
  const [isEditorLocked, setIsEditorLocked] = useState(false);

  const content = useMemo(() => getCard(card.id)?.document, [card.id, getCard]);
  const [versioningModalOpen, versionModalHandlers] = useDisclosure(false);

  const { editor, dirtyBody, onClear, onSubmit, provider, isSynced } = useAdvancedEditor({
    content,
    useVersioning: true,
  });

  const isCompleted = card.state === 'completed' || card.state === 'archived';
  const isSaveDisabled = isCompleted || !dirtyBody;

  useLeaveConfirmation(dirtyBody && !isCompleted);

  const handleSave = async () => {
    if (!editor) {
      return;
    }

    const tmp = { ...card, document: editor.getHTML() };

    await updateCard(tmp);

    onSubmit();
  };

  useEffect(() => {
    setIsEditorLocked(isCompleted);
    editor?.setEditable(!isCompleted);
  }, [isCompleted, setIsEditorLocked, editor]);

  if (!isSynced) {
    return (
      <Stack justify="center" align="center">
        <Loader color="blue" />
      </Stack>
    );
  }

  return (
    <Stack>
      <Editor
        editor={editor}
        onSave={handleSave}
        onClear={onClear}
        isLocked={isEditorLocked}
        isDisabled={isSaveDisabled}
        onShowHistory={versionModalHandlers.open}
      />
      {provider && (
        <VersioningModal
          editor={editor}
          provider={provider}
          isCompleted={isCompleted}
          isOpen={versioningModalOpen}
          onClose={versionModalHandlers.close}
        />
      )}
    </Stack>
  );
};
