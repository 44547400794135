import { Button, Divider, Group, px, Stack, Text } from '@mantine/core';
import { IconCheck, IconCircleCheck, IconExclamationCircle, IconHelp, IconX } from '@tabler/icons-react';
import { FC } from 'react';

import { useCompleteCard } from '../../../../hooks';
import { DatePicker } from '../../../DatePicker';
import { OpenRequestsList } from './components';
import { CompleteCardFormProps } from './types';

export const CompleteCardForm: FC<CompleteCardFormProps> = ({ card, onHide, completeMessage }) => {
  const { openRequests, setSnoozeDate, onSubmit } = useCompleteCard(card);

  const handleSubmit = () => {
    onSubmit();
    onHide?.();
  };

  return (
    <Stack
      style={{ cursor: 'default' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Divider label={<span style={{ fontSize: 'larger' }}>Complete this decision</span>} labelPosition="left" />
      <Stack>
        {!!completeMessage && (
          <Group>
            <IconExclamationCircle size={px('1.5rem')} color="#EC5B53" />
            <Text fw={500}>
              {completeMessage} {!!openRequests.length && 'and it has no approvals.'}
            </Text>
          </Group>
        )}

        <Group>
          <IconCircleCheck size={px('1.5rem')} color="#40826D" />
          <Text>This card will be completed, locked & moved to The Vault.</Text>
        </Group>

        <OpenRequestsList openRequests={openRequests} />

        <Group>
          <IconHelp size={px('1.5rem')} color="#F2C94C" />
          <Text>
            Revisit this card later? <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>(optional)</span>{' '}
          </Text>
          <DatePicker onConfirm={setSnoozeDate} label="Set Revisit Date" title="Revisit this card at" />
        </Group>

        <Group>
          <Button leftSection={<IconCheck size={px('1rem')} />} onClick={handleSubmit}>
            Complete
          </Button>
          <Button leftSection={<IconX size={px('1rem')} />} variant="transparent" onClick={onHide}>
            Cancel
          </Button>
        </Group>

        {/* Previous version  */}
        {/* <Group grow justify="space-between">
          <IconHelp size={px('1.5rem')} color="#F2C94C"/>
          <Stack gap={8}>
            <Group gap={8}>
              Revisit this decision at a later date? <SnoozeBtn onSnooze={setSnoozeDate} />
            </Group>
            {snoozeDateText && <Text>Set to revisit at: {snoozeDateText}</Text>}
          </Stack>
          <Group justify="flex-end">
            <ActionIcon variant="transparent" onClick={onHide}>
              <IconX size={px('1rem')} />
            </ActionIcon>
            <Button leftSection={<IconCheck size={px('1rem')} />} onClick={handleSubmit}>
              Complete
            </Button>
          </Group>
        </Group> */}
      </Stack>
    </Stack>
  );
};
